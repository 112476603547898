
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { ErrorMessages } from '@/helpers/constants'
import { getCategory, updateCategory, createCategory } from '@/services/categories.service'
import { CategoryAdmin } from '@/interfaces/admin/categories/category-admin.interface'

@Component({
  components: {
    SellerHeader,
  },
})
export default class SellerForm extends Vue {
  category: CategoryAdmin = {} as CategoryAdmin;
  categoryId: number;
  title = 'Crear Categoria'

  statusOptions = [
    { value: true, text: 'Activo' },
    { value: false, text: 'Inactivo' },
  ];
  /**
     * Determines whether the form submission button should be disabled based on the current category properties.
     *
     * @returns {boolean} - Indicates whether the button should be disabled.
     */
  get isDisabled(): boolean {
    return !this.category.name || this.category.active === null;
  }

  /**
   * Fetches category details based on the provided category ID.
   *
   * @param {number} categoryId - The ID of the category to fetch.
   */
  async getCategory(categoryId: number) {
    try {
      const category = await getCategory(categoryId);
      this.category = category;
    } catch (error) {
      Vue.$toast.error(ErrorMessages.COMMON);
    }
  }

  /**
   * Handles the form submission by creating or updating the category.
   */
  async submit() {
    const isUpdate = Boolean(this.category.id);
    const category = isUpdate ? await updateCategory(this.category) : await createCategory(this.category);
    Vue.$toast.success('Category Updated');

    /* if (!isUpdate) {
      this.$router.push({ name: 'category-details', params: { categoryId: parseInt(category.id) } });
    } */
  }

  /**
   * Executes when the component is mounted. If editing an existing category, fetches its details.
   */
  mounted() {
    if (this.$route.params.id) {
      this.title = 'Edit Category';
      this.categoryId = parseInt(this.$route.params.id);
      this.getCategory(this.categoryId);
    }
  }


}
